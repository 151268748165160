<template>
  <mobile-screen
    :header="true"
    screen-class="calendar-page gray-bg show-footer-only-on-last-screen"
  >
    <template v-slot:header>
      <calendar-header-menu>
        {{ displayDate }}
      </calendar-header-menu>
    </template>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item overflow-hidden">
        <div class="clebex-item-content-wrapper">
          <header-calendar></header-calendar>
        </div>
      </li>
    </ul>
    <calendar-list
      :cancel-fn="openDeclarationModal"
      :duplicate-fn="duplicateDeclaration"
    ></calendar-list>
    <template v-slot:footer>
      <plans-footer-menu
        :showCameraButton="showCameraButton"
        :calendar="selectedDeclaration"
      >
        <qr-code-scanner-button
          v-if="!selectedDeclaration"
          :showCameraButton="showCameraButton"/>
        <button class="cancel" v-else @click="cancelModal = true">
          <icon icon="#cx-foo2-cross-round-60x60" /></button
      ></plans-footer-menu>
    </template>
    <screen-modal
      v-if="cancelModal"
      class="confirm-modal"
      type="success"
      :confirm-button-label="
        displayLabelName('plan-calendar', 'plan-calendar', 'ok')
      "
      :cancel-button-label="
        displayLabelName('plan-calendar', 'plan-calendar', 'cancel')
      "
      :confirm-action="cancelDeclaration"
      :show="cancelModal"
      @close="cancelModal = false"
    >
      <h3 class="modal-title">
        {{
          displayLabelName(
            "plan-calendar",
            "plan-calendar",
            "declaration-cancelled"
          )
        }}
      </h3>
      <template v-if="modalData">
        <p class="text">
          {{ modalData.date }} {{ modalData.timeFrom }} -
          {{ modalData.timeTo }}
        </p>
        <p class="text">{{ modalData.name }}</p>
        <p class="text" v-if="modalData.all_parents">
          <span v-for="(parent, idx) in modalData.all_parents" :key="parent.id">
            <template v-if="idx === modalData.all_parents.length - 1">{{
              parent.name
            }}</template>
            <template v-else>{{ `${parent.name}, ` }}</template>
          </span>
        </p>
      </template>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import CalendarMixin from "@/services/mixins/calendar/calendar-mixin";
import HeaderCalendar from "@/components/calendar/HeaderCalendar";
import CalendarList from "@/components/calendar/CalendarList";
import { mapActions, mapState, mapGetters } from "vuex";
import PlanMixin from "@/services/mixins/plan/plan-mixin";
import QrCodeScannerButton from "@/components/global/QrCodeScannerButton";
import { formatDate } from "@/services/helpers";
import { DateTime } from "luxon";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { getUserId } from "@/services/http-service";
import { wsEvents } from "@/services/constants";

export default {
  name: "Calendar",
  data() {
    return {
      cancelModal: false,
      selectedMonth: null,
      channel: null,
    };
  },
  created() {
    this.getCompanyPreferences(10);
    this.getDeclarations({ startDate: null, noLoaders: false });
    this.selectPlanResource(null);
    this.selectPlanLevel(null);
  },
  mounted() {
    this.channel = subscribe(
      "declaration",
      [getUserId()],
      wsEvents.declaration.changed,
      this.wsCallback
    );
  },
  computed: {
    ...mapState("calendar", ["selectedDeclaration", "startDate"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("companyPreferences", ["preferences"]),
    ...mapState("user", ["userData"]),
    showCameraButton() {
      if (this.preferences) {
        return Boolean(
          Number(
            this.preferences.filter(
              (p) => p.preference == "SHOW_CAMERA_BUTTON"
            )[0].preference_values[0].value
          )
        );
      }
      return false;
    },
    modalData() {
      if (this.selectedDeclaration) {
        // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
        let timeFormat = this.globalTimeFormat;
        if (timeFormat.slice(-1) === "A") {
          timeFormat = timeFormat.replace(/.$/, "a");
        }
        let data = {
          date: formatDate(
            this.selectedDeclaration.datetime_from,
            this.globalDateFormat
          ),
          timeFrom: DateTime.fromISO(
            this.selectedDeclaration.datetime_from
          ).toFormat(timeFormat || "HH:mm"),
          timeTo: DateTime.fromISO(
            this.selectedDeclaration.datetime_to
          ).toFormat(timeFormat || "HH:mm"),
        };

        if (this.selectedDeclaration.resource) {
          data = {
            ...data,
            ...this.selectedDeclaration.resource,
          };
        } else if (this.selectedDeclaration.level) {
          data = {
            ...data,
            ...this.selectedDeclaration.level,
          };
        }
        return data;
      }
      return null;
    },
    displayDate() {
      return this.displayLabelName(
        "occupancy",
        "calendar",
        formatDate(this.startDate, "MMMM").toLowerCase()
      );
    },
  },
  methods: {
    ...mapActions("calendar", ["getDeclarations", "deleteDeclaration"]),
    ...mapActions("companyPreferences", ["getCompanyPreferences"]),
    cancelDeclaration() {
      this.deleteDeclaration(this.selectedDeclaration.id);
    },
    openDeclarationModal() {
      this.cancelModal = true;
    },
    duplicateDeclaration(declaration) {
      if (declaration.level) {
        this.selectPlanLevel({
          includes: ["default", "directly_declarable", "resources_count"],
          level_id: declaration.level.id,
        });
      } else {
        this.selectPlanResource(declaration.resource);
      }

      this.$router.push({ name: "r_plan-resources-slots" });
    },
    wsCallback(e) {
      const startDate = DateTime.fromISO(this.startDate).toFormat("yyyy-MM");
      const declarationDate = DateTime.fromISO(e.datetime_to).toFormat(
        "yyyy-MM"
      );
      if (startDate === declarationDate) {
        this.getDeclarations({ startDate: this.startDate, noLoaders: true });
      }
    },
  },
  watch: {
    startDate() {
      this.getDeclarations({ startDate: this.startDate, noLoaders: false });
    },
  },
  mixins: [CalendarMixin, PlanMixin],
  components: {
    HeaderCalendar,
    CalendarList,
    QrCodeScannerButton,
  },
  beforeUnmount() {
    unsubscribe(this.channel);
    this.channel = null;
  },
};
</script>
