<template>
  <section
    class="calendar-list"
    ref="list"
    v-if="declarations && declarations.data && declarations.data.length"
  >
    <ul class="list-of-events-by-date">
      <li
        class="lists-events"
        v-for="declaration in declarations.data.filter(
          (d) => d.declarations && d.declarations.length
        )"
        :key="declaration['date']"
      >
        <p
          class="event-date-label"
          :class="{ today: isToday(declaration['date']) }"
        >
          <time :datetime="declaration['date']"
            >{{
              isToday(declaration["date"])
                ? `${displayLabelName(
                    "plan-calendar",
                    "plan-calendar",
                    "today"
                  )} |`
                : ""
            }}
            {{
              displayLabelName(
                "preferences",
                "preference-category",
                formatDate(declaration["date"], "dddd").toLowerCase()
              )
            }}
            {{
              formatDate(
                declaration["date"],
                `${globalDateFormat || "DD MMMM YYYY"}`
              )
            }}</time
          >
        </p>
        <ul class="list-of-events-for-current-date">
          <li
            class="list-event"
            v-for="dec in declaration.declarations"
            :key="dec.id"
          >
            <button
              class="list-event-wrapper"
              type="button"
              :class="{
                active:
                  selectedDeclaration && selectedDeclaration.id === dec.id,
              }"
              @click="selectDeclaration(dec)"
              @contextmenu="showContextMenu(dec, $event)"
            >
              <span class="event-title subject" v-if="dec.subject">{{
                dec.subject
              }}</span>
              <span class="event-title" v-if="dec.level">{{
                dec.level.name
              }}</span>
              <span class="event-title" v-else-if="dec.resource">{{
                dec.resource.name
              }}</span>
              <span class="event-time">
                <time class="from" :datetime="dec.datetime_from">{{
                  displayDate(dec.datetime_from, globalTimeFormat || "HH:mm")
                }}</time>
                &nbsp;-&nbsp;
                <time class="to" :datetime="dec.datetime_to">{{
                  displayDate(dec.datetime_to, globalTimeFormat || "HH:mm")
                }}</time>
              </span>
              <span class="event-description">
                {{ displayDescription(dec) }}
              </span>
            </button>
          </li>
        </ul>
      </li>
    </ul>
  </section>
  <section class="context-menu icon-msg1" v-if="contextMenu && declaration">
    <div class="backdrop" @click="closeContextMenu"></div>
    <ul
      class="options-list"
      :style="{
        top: contextTop ? `${contextTop}px` : '',
      }"
    >
      <li class="context-item">
        <button class="context-button" @click="duplicateFn(declaration)">
          <span>{{
            displayLabelName("plan-calendar", "plan-calendar", "duplicate")
          }}</span>
          <icon icon="#cx-msg1-duplicate" />
        </button>
      </li>
      <li class="context-item">
        <button class="context-button" @click="deleteDecalaration(declaration)">
          <span>{{
            displayLabelName("plan-calendar", "plan-calendar", "delete")
          }}</span>
          <icon icon="#cx-msg1-delete-01" />
        </button>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { displayDateWithTimezone, formatDate } from "@/services/helpers";
import dayjs from "dayjs";

export default {
  name: "CalendarList",
  data() {
    return {
      touchInterval: null,
      contextMenu: null,
      contextTop: null,
      selectedEl: null,
      declaration: null,
      contextOutOfScreen: false,
    };
  },
  computed: {
    ...mapState("calendar", ["declarations", "selectedDeclaration"]),
    ...mapState("timezone", ["timezones"]),
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
  },
  updated() {
    if (
      this.declarations &&
      this.declarations.data &&
      this.declarations.data.length
    ) {
      const el = this.$refs.list.closest(".screen-content");
      if (el) {
        el.addEventListener("scroll", this.debounceCloseContextMenu);
      }
    }
  },
  methods: {
    ...mapActions("calendar", ["setSelectedDeclaration"]),
    formatDate(date, format) {
      return formatDate(date, format);
    },
    displayDescription(declaration) {
      const { level, resource } = declaration;
      const parents = this.getDeclarationParents(declaration);
      let name = [];

      if (resource && resource.level && resource.level.name && !level) {
        name.push(resource.level.name);
      }

      if (parents && parents.length) {
        return `${name.join(", ")}${
          name.length ? ", " : ""
        }${this.displayParentNames(parents)}`;
      }

      return name.join(", ");
    },
    isToday(date) {
      return dayjs().format("DD-MM-YYYY") === dayjs(date).format("DD-MM-YYYY");
    },
    selectDeclaration(dec) {
      if (this.selectedDeclaration && this.selectedDeclaration.id === dec.id) {
        this.setSelectedDeclaration(null);
      } else {
        this.setSelectedDeclaration(dec);
      }
    },
    showContextMenu(declaration, e) {
      e.preventDefault();
      this.declaration = declaration;
      this.contextMenu = true;
      const contextMenuHeight = 91;
      const contextMenuMargin = 24;
      const el = e.currentTarget;
      if (el) {
        const rect = el.getBoundingClientRect();
        const screenEl = el.closest(".screen");
        const screenRect = screenEl.getBoundingClientRect();

        let topPosition = rect.top + window.scrollY + rect.height;

        if (topPosition + contextMenuHeight > screenRect.bottom) {
          topPosition = rect.top - contextMenuHeight - contextMenuMargin;
        }

        this.contextTop = topPosition;
        el.style.zIndex = 201;
        this.selectedEl = el;
      }
    },
    closeContextMenu() {
      this.selectedEl.removeAttribute("style");
      this.selectedEl = null;
      this.contextMenu = false;
    },
    debounceCloseContextMenu() {
      setTimeout(() => {
        if (this.contextMenu) {
          this.closeContextMenu();
        }
      }, 50);
    },
    deleteDecalaration(declaration) {
      this.setSelectedDeclaration(declaration);
      this.closeContextMenu();
      this.cancelFn(declaration);
    },
    getDeclarationParents(declaration) {
      const { resource, level } = declaration;
      if (level) {
        return level.all_parents;
      } else {
        if (resource) {
          const resourceLevel = resource.level;
          if (resourceLevel) {
            return resource.level.all_parents;
          }
          return null;
        }
      }
      return null;
    },
    displayParentNames(parents) {
      const filteredParents = parents.filter((item) => item.name);
      return filteredParents.map((parent) => parent.name).join(", ");
    },
    displayDate(date, format) {
      if (this.timezones && this.timezones.data) {
        return displayDateWithTimezone({
          date,
          format,
          timezones: this.timezones,
        });
      }
      return "";
    },
  },
  beforeUnmount() {
    if (
      this.declarations &&
      this.declarations.data &&
      this.declarations.data.length
    ) {
      const el = this.$refs.list.closest(".screen-content");
      if (el) {
        el.removeEventListener("scroll", this.debounceCloseContextMenu);
      }
    }
  },
  props: {
    cancelFn: {
      type: Function,
      required: true,
    },
    duplicateFn: {
      type: Function,
      required: true,
    },
  },
};
</script>
